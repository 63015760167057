import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Save from "@mui/icons-material/Save"
import ResetIcon from "@mui/icons-material/Refresh"

import { useState } from "react"
import { User } from "../../../interface/User"
import CircularProgress from "@mui/material/CircularProgress"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import IconButton from "@mui/material/IconButton"
import { TradingPair } from "../../../interface/TradingPair"
import { v4 as uuid } from "uuid"
interface MultiPairAddModalProps
{
  open: boolean
  userDetails: User
  onClose: () => void
  updateUserSettings: (user: Partial<User>) => Promise<void>
}
export const MultiPairAddModal = ({ open, userDetails, onClose, updateUserSettings }: MultiPairAddModalProps) =>
{
  const [ currentTradingPair, setPair ] = useState("")
  const [ base, setBase ] = useState("")
  const [ maxInvest, setMaxInvest ] = useState(10)
  const [ currentInvest, setCurrentInvest ] = useState(0)
  const [ interval, setInterval ] = useState("5m")
  const [ isStopLossEnabled, setStopEnabled ] = useState(false)
  const [ useDynamicStop, setDynamicStop ] = useState(false)
  const [ stopLossPercentage, setStopPercent ] = useState(-10)

  const [ saving, setSaving ] = useState(false)

  const onSave = async () => {

    setSaving(true)

    const newPair: TradingPair = {
      maxInvest,
      pair: currentTradingPair,
      currentInvest,
      useDynamicStop,
      isStopLossEnabled,
      stopLossPercentage,
      interval,
      pairId: uuid(),
      isEnabled: true,
      base,
    }

    let combined: TradingPair[] = []

    if(userDetails && userDetails?.tradingPairs && userDetails?.tradingPairs?.length > 0)
    {
      combined.push(...userDetails.tradingPairs)
      combined.push(newPair)
    }
    if(userDetails && userDetails?.tradingPairs && userDetails?.tradingPairs?.length === 0) combined.push(newPair)

    await updateUserSettings({ tradingPairs: combined })

    onClose()
    setSaving(false)

  }

  return (<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>Add new pair</DialogTitle>
    <DialogContent>
      <Grid container direction="column" spacing={2}>

        <Grid item>
          <TextField fullWidth placeholder="Pair" value={currentTradingPair} onChange={(e: any) => setPair(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField fullWidth placeholder="Base" value={base} onChange={(e: any) => setBase(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField label="Max Investment" InputProps={{ startAdornment: "$" }} fullWidth placeholder="Max Invest" value={maxInvest} onChange={(e: any) => setMaxInvest(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField 
            label="Current Investment" 
            InputProps={{ startAdornment: "$", endAdornment: <IconButton onClick={() => setCurrentInvest(0)}><ResetIcon /></IconButton> }} 
            fullWidth 
            placeholder="Current Investment" 
            value={currentInvest} 
            onChange={(e: any) => setCurrentInvest(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <TextField label="Chart Interval" fullWidth placeholder="Chart Interval" value={interval} onChange={(e: any) => setInterval(e.target.value)}></TextField>
        </Grid>

        <Grid item>
          <FormControlLabel label="Enable Stop Loss" control={<Switch checked={isStopLossEnabled} onChange={(_, checked) => setStopEnabled(checked)} />} ></FormControlLabel>
        </Grid>

        <Grid item>
          <FormControlLabel label="Enable Dynamic Stop" control={<Switch checked={useDynamicStop} onChange={(_, checked) => setDynamicStop(checked)} />} ></FormControlLabel>
        </Grid>

        { isStopLossEnabled && <Grid item>
          <TextField label="Stop Loss Percentage (Calculated automatically when dynamic is enabled)" InputProps={{ endAdornment: "%" }} disabled={useDynamicStop} fullWidth placeholder="Stop Loss Percentage" value={stopLossPercentage} onChange={(e: any) => setStopPercent(e.target.value)}></TextField>
        </Grid> }



      </Grid>
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose}>Close</Button>
      <Button disabled={saving} onClick={onSave} startIcon={saving ? <CircularProgress size={16} /> : <Save />}>Save</Button>
    </DialogActions>
  </Dialog>)
}