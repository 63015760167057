import ListItem from "@mui/material/ListItem"
import { IChat } from "../../interface/Chat"
import IconButton from "@mui/material/IconButton"
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import ChatIcon from '@mui/icons-material/Forum';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../interface/ApplicationState";
import { markReadBegin, removeConversationStart, removeMyChatStart, setCurrentChat } from "../../redux/actions/chatActions";
import { purple } from "@mui/material/colors";
import { useGravatar } from "../modals/UserDetails/hooks/useGravatar";

interface ChatPreviewCardProps
{
  conversationItem: IChat
  isAdmin?: boolean
}

export const ChatPreviewCard = ({ conversationItem, isAdmin }: ChatPreviewCardProps) => {

  const { gravatarImage } = useGravatar(100, conversationItem.email)
  const dispatch = useDispatch()
  const isRemovingMyChat = useSelector((state: ApplicationState) => state.chat.isRemovingMyChat)

  const handleGoIntoChat = (conversationItem: IChat) =>
    {
      dispatch(setCurrentChat(conversationItem._id))
      dispatch(markReadBegin(conversationItem._id))
    }
  
    const handleRemoveChat = (conversationItem: IChat) =>
    {
      if(!isAdmin) dispatch(removeMyChatStart(conversationItem._id))
      if(isAdmin) dispatch(removeConversationStart(conversationItem._id))
    }
  
    const getDescription = (conversationItem: IChat) =>
    {
      if(isAdmin && conversationItem.unSeenUserMessageCount > 0) return `${conversationItem.unSeenUserMessageCount} messages not seen by user - `
      if(!isAdmin && conversationItem.unSeenAdminMessageCount > 0) return `${conversationItem.unSeenAdminMessageCount} messages not seen by support - `
      return ""
    }

  return(
    <ListItem 
    secondaryAction={
      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveChat(conversationItem)} disabled={isRemovingMyChat}>
        <DeleteIcon />
      </IconButton>
    }>
    <ListItemButton style={localStyles.listButton} onClick={() => handleGoIntoChat(conversationItem)}>

    <ListItemAvatar>
    <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} overlap="circular" color="primary" badgeContent={isAdmin ? conversationItem.unSeenAdminMessageCount : conversationItem.unSeenUserMessageCount}>
      <Avatar style={localStyles.avatar} src={gravatarImage}>
        <ChatIcon />
      </Avatar>
    </Badge>

    </ListItemAvatar>
      <ListItemText 
        secondaryTypographyProps={{ fontSize: 10 }} 
        primaryTypographyProps={{ fontSize: 14 }} 
        primary={`${conversationItem.email}: ${conversationItem.subject}`} 
        secondary={`${getDescription(conversationItem)}${moment(conversationItem.timestamp).fromNow()}`} />
    </ListItemButton>

  </ListItem>
  )
}

const localStyles = {
  listButton: {
    borderRadius: 12
  },
  avatar: {
    backgroundColor: purple[400]
  }
}