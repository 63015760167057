import { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Insights from '@mui/icons-material/Insights'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ApplicationState } from '../../interface/ApplicationState';
import { useSelector, useDispatch } from 'react-redux';
import { setUserBegin, toggleDrawer, updateLocalUser } from '../../redux/actions/userActions';
import { teal } from '@mui/material/colors';
import { BullishBearish } from '../BullishBearish';
import { SubscriptionStatusBadge } from '../SubscriptionStatusBadge';
import { UserProfileModal } from '../modals/UserProfile/UserProfileModal';
import moment from 'moment';
import { CustomAppBar } from './CustomAppBar';
import { DarkModeSwitch } from '../DarkModeSwitch';
import { PairSelector } from './PairSelector';
import SmartBanner from 'react-smartbanner';
import { useGravatar } from "../modals/UserDetails/hooks/useGravatar";



export const ResponsiveAppBar = () => {

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const [ showProfileModal, setShowProfileModal ] = useState(false)
  const isDrawerOpen = useSelector((state: ApplicationState) => state.userStore.isDrawerOpen)
  const { gravatarImage } = useGravatar(100, user.email)
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () =>
  {
    localStorage.clear()
    window.location.href = "/"
  }

  const handleCloseProfileModal = () =>
  {
    setShowProfileModal(false)
    setAnchorEl(null);
  }

  return (
    <Box sx={{ display: 'flex' }}>

      <CssBaseline />

      <SmartBanner title="AlgoTrader" />

      <CustomAppBar position="fixed">
        <Toolbar disableGutters>
        <div style={styles.title} >
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleDrawer(!isDrawerOpen))}
            edge="start"
            sx={{
              marginRight: 2,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Insights style={styles.icon} fontSize="medium" />
          <Typography variant="body1" fontWeight="700">AlgoTrader Trading Bot</Typography>
        </div>
        <Box sx={{ flex: '1', color: "red" }}> </Box>


        <Box sx={{ flex: '1', color: "red" }}> </Box>

      
      <PairSelector />

      <SubscriptionStatusBadge />

        { user && user.lastTakeProfitEvent && <Typography color="textSecondary" variant="caption">Bot take profit in effect - {moment(user.lastTakeProfitEvent).fromNow()}</Typography>}

        { user && user.tradingPair && user.chartInterval && <BullishBearish marketId={user.tradingPair} interval={user.chartInterval} /> } 

        <DarkModeSwitch checked={user.isDarkModeOn} onChange={(e: any) => dispatch(setUserBegin({ isDarkModeOn: e.target.checked } ))} />

        <Avatar src={gravatarImage} style={styles.avatar} onClick={handleClick}></Avatar>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose} >
            <MenuItem onClick={() => setShowProfileModal(true)}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

         
        </Toolbar>

        <UserProfileModal open={showProfileModal} handleClose={handleCloseProfileModal} user={user} />

    </CustomAppBar>
    </Box>

  );
}

const styles = {
  container: {
    marginRight: 16,
  },
  icon: {
    marginRight: 6,
  },
  title: {
    marginLeft: 16,
    flexDirection: 'row' as 'row',
    display: 'flex' as 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: teal[400],
    marginRight: 12,
    marginLeft: 12,

  }
}
