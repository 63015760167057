import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import { setBacktestConfig } from "../../../redux/reducers/backtesting/backtestingReducer"
import { styles } from "./CreateCustomBackTest"


export const ChartIntervalConfig = () => {

  const dispatch = useDispatch()
  const config = useSelector((state: ApplicationState) => state.backtesting.config)
  
  return (
    <Grid item>
    <Typography sx={{ mb: 1 }} fontWeight="700">Select your chart interval (a longer interval will look further back in time)</Typography>
    <Card style={styles.card}>
    <Chip sx={{ mr: 1 }} label="1 minute" onClick={() => dispatch(setBacktestConfig({ chartInterval: "1m" }))} variant={config?.chartInterval === "1m" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="5 minute"onClick={() => dispatch(setBacktestConfig({ chartInterval: "5m" }))} variant={config?.chartInterval === "5m" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="15 minute" onClick={() => dispatch(setBacktestConfig({ chartInterval: "15m" }))} variant={config?.chartInterval === "15m" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="30 minute" onClick={() => dispatch(setBacktestConfig({ chartInterval: "30m" }))} variant={config?.chartInterval === "30m" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="1 hour" onClick={() => dispatch(setBacktestConfig({ chartInterval: "1h" }))} variant={config?.chartInterval === "1h" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="4 hours" onClick={() => dispatch(setBacktestConfig({ chartInterval: "4h" }))} variant={config?.chartInterval === "4h" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="12 hours" onClick={() => dispatch(setBacktestConfig({ chartInterval: "12h" }))} variant={config?.chartInterval === "12h" ? "filled" : "outlined"} />
    <Chip sx={{ mr: 1 }} label="1 day" onClick={() => dispatch(setBacktestConfig({ chartInterval: "1d" }))} variant={config?.chartInterval === "1d" ? "filled" : "outlined"} />
    </Card>
  </Grid>
  )
}