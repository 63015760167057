

import { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


import { Transition } from '../../components/modals/SellModal/Transition';
import { SignalItem } from '../../interface/SignalItem';
import { useEffect } from 'react';
import { LogApi } from '../../api/LogApi';
import { Candle } from '../../interface/Candle';
import { MarketChart } from '../../components/charts/MarketChart';
import { IRunEvent } from '../../interface/IRunEvent';

import moment from 'moment'
import { RunEventTable } from './RunEventTable';

interface SignalDetailsProps
{
  open: boolean
  handleClose: () => void
  signal: SignalItem
}

export const SignalDetails = ({ open, handleClose, signal }: SignalDetailsProps) =>
{

  const [ candles, setCandles ] = useState<Candle[] | undefined>()
  const [ runEvents, setRunEvents ] = useState<IRunEvent[] | undefined>()

  const [ loading, setLoading ] = useState(false)
  const [ chartInterval, setChartInterval ] = useState("1m")


  useEffect(() => {

    getData()
    
  }, [ chartInterval ])

  const getData = async () =>
  {
    setLoading(true)
    await getCandles()
    await getRunEvents()
    setLoading(false)

  }

  const getRunEvents = async () =>
  {
    try
    {
      const runEvents = await new LogApi().getRunEvents("desc", 100, 0, moment().subtract("2", "weeks").toISOString())

      const runEventsList = []
      if(runEvents)
      {
        for(const runEvent of runEvents)
        {
          if(runEvent.marketId === signal.pair) runEventsList.push(runEvent)
        }
        setRunEvents(runEventsList)
      }

    }
    catch(error)
    {
      // Bit of an issue getting those candles
    }
  }

  const getCandles = async () =>
  {
    try
    {
      const candles = await new LogApi().getCandles(signal.pair, chartInterval)
      setCandles(candles)
    }
    catch(error)
    {
      // Bit of an issue getting those candles
    }
  }

  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Signal Details {signal.pair} </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      
      </DialogContentText>

   

      { loading ? <div style={styles.chartContainer}><CircularProgress /></div> : 
      <>
      { runEvents && runEvents.length > 0 ? <RunEventTable runEvents={runEvents} /> : 
      
      <div style={styles.pill}>
        <Typography textAlign="center" variant="subtitle2" color="textSecondary" >Not Invested in signal at the moment</Typography> 
      </div>
      
      }
      <MarketChart tradingPair={signal.pair} chartInterval={chartInterval} candles={candles} />
      </>
      }
      
    <div style={styles.buttonGroup}>

    <Button variant={ chartInterval === "1m" ? `outlined` : `text`} onClick={() => setChartInterval("1m")}>1m</Button>
    <Button variant={ chartInterval === "3m" ? `outlined` : `text`} onClick={() => setChartInterval("3m")}>3m</Button>

    <Button variant={ chartInterval === "5m" ? `outlined` : `text`} onClick={() => setChartInterval("5m")}>5m</Button>
    <Button variant={ chartInterval === "15m" ? `outlined` : `text`} onClick={() => setChartInterval("15m")}>15m</Button>
    <Button variant={ chartInterval === "30m" ? `outlined` : `text`} onClick={() => setChartInterval("30m")}>30m</Button>
    <Button variant={ chartInterval === "1h" ? `outlined` : `text`} onClick={() => setChartInterval("1h")}>1h</Button>
    <Button variant={ chartInterval === "4h" ? `outlined` : `text`} onClick={() => setChartInterval("4h")}>4h</Button>
    <Button variant={ chartInterval === "12h" ? `outlined` : `text`} onClick={() => setChartInterval("12h")}>12h</Button>
    <Button variant={ chartInterval === "1d" ? `outlined` : `text`} onClick={() => setChartInterval("1d")}>1d</Button>

    </div>
   


    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}

const styles = {
  chartContainer: {
    height: 200,
    flex: 1,
    display: 'flex' as 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonGroup: {
    textAlign: "center" as 'center'
  },
  pill: {
    borderRadius: 10,
    backgroundColor: "#5C6BC0",
    padding: 8,
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 6
  }
}