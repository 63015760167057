import { useState } from "react"
import { BackTestApi } from "../../../../api/BackTestApi"
import { BacktestStore } from "../../../../redux/reducers/backtesting/backtestingReducer"
import { AlertColor } from "@mui/material"


export const useSaveBacktest = () =>
{
  const [ saving, setSaving ] = useState(false)
  const [ addResult, setAddResult ] = useState<{ message: string, color: AlertColor} | undefined>(undefined)

  
  const handleSave = async (settings: BacktestStore) => {

    setSaving(true)

    try
    {
      // Remove the ID if it exists as the backend will create it for us
      const response = await new BackTestApi().addCustomTest(settings)
      if(response) setAddResult({ message: "Added new test OK", color: "success"})
    }
    catch(error)
    {
      setAddResult({ message: "Looks like something went wrong while adding the custom backtest", color: "error" })
    }

    setSaving(false)
  }

  return { handleSave, addResult, saving, setAddResult }

  
}