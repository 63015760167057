import Grid from "@mui/material/Grid"
import { User } from "../../../interface/User"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItem from "@mui/material/ListItem"
import Avatar from "@mui/material/Avatar"
import ListItemText from "@mui/material/ListItemText"
import Edit from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"

import Typography from "@mui/material/Typography"
import { teal } from "@mui/material/colors"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import { MultiPairEditModal } from "./MultiPairEditModal"
import { useState } from "react"
import { TradingPair } from "../../../interface/TradingPair"
import Button from "@mui/material/Button"
import { MultiPairAddModal } from "./MultiPairAddModal"

interface UserMultiPairSectionProps
{
  userDetails: User
  updateUserSettings: (settings: Partial<User>) => Promise<void>
}

export const UserMultiPairSection = ({ userDetails, updateUserSettings }: UserMultiPairSectionProps) => {

  const [ showEditModal, setShowEditModal ] = useState<TradingPair | undefined>()
  const [ showAddModal, setShowAddModal ] = useState(false)


  return (
    <Grid container direction="column">
      <Grid item container>

        <Grid item flex={1}>
        <Typography fontWeight="700">Multi Trading Pairs</Typography>
        </Grid>

        <Grid item>
        <Button startIcon={<AddIcon />} onClick={() => setShowAddModal(true)}>Add new pair</Button>
        </Grid>

      </Grid> 
      <Grid item>
      { userDetails.tradingPairs?.map((pair, key) => {
        return (
          <ListItem key={key} 
            secondaryAction={<IconButton onClick={() => setShowEditModal(pair)}><Edit /></IconButton>}>
          <ListItemAvatar>
            <Avatar style={styles.avatar} >
              {pair.pair[0] + pair.pair[1] + pair.pair[2]}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={pair.pair} secondary={`Candles: ${pair.interval} | Current investment: $${pair.currentInvest} | Max Invest: $${pair.maxInvest} | Stop ${pair.isStopLossEnabled ? "(Enabled)" : "(Disabled)"} ${pair.stopLossPercentage}% | Dynamic Stop: ${pair.useDynamicStop ? "Enabled" : "Manual"}`} />
       </ListItem>
        )
      })}
      </Grid>

      <Divider />

      { showEditModal && <MultiPairEditModal userDetails={userDetails} open={!!showEditModal} tradingPair={showEditModal} onClose={() => setShowEditModal(undefined)} updateUserSettings={updateUserSettings}  /> }
      { showAddModal && <MultiPairAddModal userDetails={userDetails} open={!!showAddModal} onClose={() => setShowAddModal(false)} updateUserSettings={updateUserSettings}  /> }

    </Grid>
  )
}

const styles = {
  avatar: {
    fontSize: 10,
    backgroundColor: teal[400]
  }
}