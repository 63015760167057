import ListItem from "@mui/material/ListItem"
import { Conversation } from "../../interface/Conversation"
import Card from "@mui/material/Card"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import moment from "moment"
import { blue, green } from "@mui/material/colors"
import { useGravatar } from '../modals/UserDetails/hooks/useGravatar';

interface MessageCardProps
{
  message: Conversation
}

export const MessageCard = ({ message }: MessageCardProps) => {

  const { gravatarImage } = useGravatar(100, message.from)

  return (
    <Card style={{ ...localStyles.card, float: message.isAdmin ? "right" : "left" }} >
    <ListItem >

    <ListItemAvatar>
    <Avatar style={{ backgroundColor: message.isAdmin ? blue[500] : green[500] }} src={gravatarImage} >
      {message.from[0].toUpperCase() + message.from[1].toUpperCase()}
    </Avatar>
    </ListItemAvatar>
      <ListItemText primary={message.from} secondary={message.message} primaryTypographyProps={{ fontWeight: "700" }} />
    </ListItem>

    <div style={localStyles.footer}>
    <Typography variant="caption" color="textSecondary" >{moment(message.timestamp).fromNow()}</Typography>
    </div> 
    </Card>
  )
}


const localStyles = {
  card: {
    marginTop: 8,
    marginBottom: 8,
    padding: 6,
    width: 500,
    borderRadius: 12,
  },
  footer: {
    justifyContent: "flex-end",
    display: 'flex' as 'flex'
  }
}