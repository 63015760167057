import List from '@mui/material/List';
import { styles } from './ChatWindow';
import { IChat } from '../../interface/Chat';
import { NoConversations } from '../placeholder/NoConversations';
import { SearchBar } from '../SearchBar';
import { useState } from 'react';
import { useSearchFilter } from './hooks/useSearchFilter';
import { ChatPreviewCard } from './ChatPreviewCard';

interface PastHelpRequestsProps
{
  conversations: IChat[]
  isAdmin: boolean | undefined
}
export const PastHelpRequests = ({ conversations, isAdmin }: PastHelpRequestsProps) =>
{
  const [ query, setQuery ] = useState("")
  const filteredConversations = useSearchFilter(query, conversations)

  return(
    <>
    <div style={localStyles.searchContainer}><SearchBar placeholder='Search conversations' setQuery={setQuery} /></div>
    <List style={styles.list}>
    {
     filteredConversations && filteredConversations.length > 0 ? filteredConversations.map((conversationItem, key) => <ChatPreviewCard isAdmin={isAdmin} key={key} conversationItem={conversationItem} />) : <NoConversations isAdmin={isAdmin} />
    } 

  </List>
  </>

  )
}

const localStyles = {
  searchContainer: {
    marginRight: 16,
    marginLeft: 16,
  }
}