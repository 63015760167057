
import List from '@mui/material/List';
import { styles } from './ChatWindow';
import { Conversation } from '../../interface/Conversation';
import { useEffect, useRef } from 'react';
import { NoMessagesPlaceholder } from './NoMessagesPlaceholder';
import { MessageCard } from './MessageCard';

interface MessagesViewProps
{
  messageThread: Conversation[] | undefined
}

export const MessagesView = ({ messageThread }: MessagesViewProps) =>
{
  const listRef = useRef(null);

  useEffect(() => {

      // Scroll to the bottom of the list whenever items change
      // @ts-ignore
      if(listRef && listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;

  }, [messageThread])

  return(
    <List style={styles.list} ref={listRef}>

     { !messageThread || messageThread.length === 0 && <NoMessagesPlaceholder  animation={require("../../animations/chat-lottie.json")} height={200} width={200} loop={false} text={`It's pretty quite here`} />} 

    { messageThread?.map((message, key) => <MessageCard key={key} message={message} />)}

    </List>
  )
}
